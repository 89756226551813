export interface Environment {
  baseUrl: string;
  apiBaseUrl: string;
  accessTokenKey: string;
  bearerPrefix: string;
  accessDeniedPath: string;
  expires?: number;
  secure?: boolean;
  domain?: string;
}

export type EnvType = 'development' | 'staging' | 'production';

export const env: EnvType = 'production';

const environment = require(`./environment.${env}`).default;
export default environment as Environment;

import {
  collection,
  getDocs,
  query,
  where,
  orderBy,
  getDoc,
  doc,
  setDoc
} from 'firebase/firestore';
import { db } from './firebase-db';
import { Transaction } from 'src/types/transaction';

export const getUserByUsername = (username: string) => {
  return getDocs(
    query(
      collection(db, 'user'),
      where('username', '==', username.replace('@', ''))
    )
  );
};

export const getUserByUid = (uid: string) => {
  return getDoc(doc(db, 'user', uid));
};

export const getUserAvailablePaymentMethod = ({ uid }) => {
  return getDocs(
    query(
      collection(db, 'user', uid, 'paymentMethod'),
      where('isEnabled', '==', true),
      orderBy('createdAt', 'asc')
    )
  );
};

export const getPaymentMethod = ({ uid, id }) => {
  return getDoc(doc(db, 'user', uid, 'paymentMethod', id));
};

export const createTransaction = ({
  uid,
  docId,
  transaction
}: {
  uid: string;
  docId: string;
  transaction: Transaction;
}) => {
  return setDoc(doc(db, 'user', uid, 'transaction', docId), transaction);
};

import React, { createContext, useEffect, useReducer } from 'react';
import { FC, ReactNode } from 'react';
import { User } from 'src/types/user';
import SplashScreen from 'src/components/SplashScreen';
import { auth } from 'src/lib/firebase';
import { onAuthStateChanged } from 'firebase/auth';
import {
  createUserWithEmailAndPassword as FirabaseCreateUserWithEmailAndPassword,
  signInWithEmailAndPassword as FirabaseSignInWithEmailAndPassword,
  GoogleAuthProvider,
  FacebookAuthProvider,
  signInWithPopup,
  signInAnonymously as FirebaseSignInAnonymously,
  signOut
} from 'firebase/auth';
import * as FirebaseService from 'src/lib/firebase.service';
import { useLocation } from 'react-router-dom';

interface AuthState {
  isInitialised: boolean;
  isAuthenticated: boolean;
  user: User | null;
}

interface AuthContextValue extends AuthState {
  method: 'FirebaseAuth';
  createUserWithEmailAndPassword: (
    email: string,
    password: string
  ) => Promise<any>;
  signInWithEmailAndPassword: (email: string, password: string) => Promise<any>;
  signInAnonymously: () => Promise<any>;
  signInWithGoogle: () => Promise<any>;
  signInWithFacebook: () => Promise<any>;
  logout: () => Promise<void>;
}

interface AuthProviderProps {
  children: ReactNode;
}

type AuthStateChangedAction = {
  type: 'AUTH_STATE_CHANGED';
  payload: {
    isAuthenticated: boolean;
    user: User | null;
  };
};

type Action = AuthStateChangedAction;

const initialAuthState: AuthState = {
  isAuthenticated: false,
  isInitialised: false,
  user: null
};

const reducer = (state: AuthState, action: Action): AuthState => {
  switch (action.type) {
    case 'AUTH_STATE_CHANGED': {
      const { isAuthenticated, user } = action.payload;

      return {
        ...state,
        isAuthenticated,
        isInitialised: true,
        user
      };
    }
    default: {
      return { ...state };
    }
  }
};

const AuthContext = createContext<AuthContextValue>({
  ...initialAuthState,
  method: 'FirebaseAuth',
  createUserWithEmailAndPassword: () => Promise.resolve(),
  signInAnonymously: () => Promise.resolve(),
  signInWithEmailAndPassword: () => Promise.resolve(),
  signInWithGoogle: () => Promise.resolve(),
  signInWithFacebook: () => Promise.resolve(),
  logout: () => Promise.resolve()
});

export const AuthProvider: FC<AuthProviderProps> = ({ children }) => {
  const location = useLocation();
  const [state, dispatch] = useReducer(reducer, initialAuthState);

  const signInAnonymously = () => {
    return FirebaseSignInAnonymously(auth);
  };

  const signInWithEmailAndPassword = (
    email: string,
    password: string
  ): Promise<any> => {
    return FirabaseSignInWithEmailAndPassword(auth, email, password);
  };

  const signInWithGoogle = (): Promise<any> => {
    return signInWithPopup(auth, new GoogleAuthProvider());
  };

  const signInWithFacebook = (): Promise<any> => {
    return signInWithPopup(auth, new FacebookAuthProvider());
  };

  const createUserWithEmailAndPassword = async (
    email: string,
    password: string
  ): Promise<any> => {
    return FirabaseCreateUserWithEmailAndPassword(auth, email, password);
  };

  const logout = (): Promise<void> => {
    return signOut(auth);
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async initUser => {
      let user: User = initUser;

      if (user) {
        user = {
          uid: initUser.uid,
          photoUrl: initUser.photoURL,
          email: initUser.email,
          name: initUser?.displayName || 'A User',
          isAnonymous: initUser?.isAnonymous,
          username: null
        } as User;
        const shapshot = await FirebaseService.getUserByUid(user.uid);
        const firebaseUser = shapshot.data();

        if (firebaseUser) {
          user = {
            ...initUser,
            ...firebaseUser
          };
        }

        dispatch({
          type: 'AUTH_STATE_CHANGED',
          payload: {
            isAuthenticated: true,
            user
          }
        });
      } else {
        dispatch({
          type: 'AUTH_STATE_CHANGED',
          payload: {
            isAuthenticated: false,
            user: null
          }
        });
      }
    });

    if (location.pathname === '/') {
      return dispatch({
        type: 'AUTH_STATE_CHANGED',
        payload: {
          isAuthenticated: false,
          user: null
        }
      });
    }
    return unsubscribe;
  }, [dispatch]);

  if (!state.isInitialised) {
    return <SplashScreen />;
  }

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'FirebaseAuth',
        signInAnonymously,
        createUserWithEmailAndPassword,
        signInWithEmailAndPassword,
        signInWithGoogle,
        signInWithFacebook,
        logout
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;

import React from 'react';
import { LayeredImage } from 'react-layered-image';

const LayeredHeroImage = () => {
  const heroImageLayers = [
    'static/home/hero/bg.png',
    'static/home/hero/layer1.png',
    'static/home/hero/layer2.png',
    'static/home/hero/layer3.png',
    'static/home/hero/layer4.png',
    'static/home/hero/layer5.png'
  ];

  return (
    <LayeredImage
      layers={heroImageLayers}
      aspectRatio={4 / 4}
      borderRadius={0}
      lightColor="transparent"
      shadowColor="transparent"
      style={{ width: 520 }}
    />
  );
};

export default LayeredHeroImage;

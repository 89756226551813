import React from 'react';
import type { FC } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Container,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import type { Theme } from 'src/theme';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { articles } from './data';

interface FAQSProps {
  className?: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.common.white,
    display: 'flex',
    flexDirection: 'column',
  },
  cardContainer: {
    color: theme.palette.common.black,
    flex: 1,
    paddingBottom: 20,
    paddingTop: 20,
    paddingLeft: 0,
    paddingRight: 0,
    marginBottom: 10,
    borderBottom: '3px solid #000',
  },
  cardContent: {
    marginTop: -10
  },
  header: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: 30,
    [theme.breakpoints.down('md')]: {
      marginBottom: 15,
    },
  },
  accordion: {
    maxWidth: 1100
  }
}));

const FAQS: FC<FAQSProps> = ({ className, ...rest }) => {
  const classes = useStyles();
  const data = articles;
  return (
    <Box
      className={clsx(classes.root, className)}
      {...rest}
      my={14}
    >
      <Container className={classes.header} >
        <Typography
          component="p"
          variant="overline"
          fontWeight={500}
          style={{ color: '#838383' }}
          align="center">
          FIND THE ANSWERS YOU'RE LOOKING FOR</Typography>
        <Typography variant="h1" align="center" fontWeight={700}>Frequently Ask Questions</Typography>
      </Container>
      <Container className={classes.accordion}>
        {data.map(article => (
          <div>
            <Accordion className={classes.cardContainer} elevation={0} disableGutters>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`${article.content}`}
                id={article.title}
              >
                <Typography sx={{ fontWeight: 700 }} variant='h3'>{article.title}</Typography>
              </AccordionSummary>
              <AccordionDetails className={classes.cardContent}>
                <Typography sx={{ paddingTop: 1 }} maxWidth="lg">
                  {article.content}
                </Typography>
              </AccordionDetails>
            </Accordion>
          </div>
        ))}
      </Container>
    </Box>
  );
};

FAQS.propTypes = {
  className: PropTypes.string
};

export default FAQS;

import React, { useState } from 'react';
import { TypeAnimation } from 'react-type-animation';

export interface TypingTextProps {
  sequence: any[];
  style?: any;
}

const TypingText: React.FC<TypingTextProps> = props => {
  return (
    <TypeAnimation speed={5} repeat={Infinity} deletionSpeed={50} {...props} />
  );
};

export default TypingText;

export enum PaymentOption {
  cash = 'cash',
  gcash = 'gcash',
  maya = 'maya',
  shopeePay = 'shopeepay',
  grabPay = 'grabpay',
  alipay = 'alipay',
  palawanpay = 'palawanpay',

  bdo = 'bdo',
  bpi = 'bpi',
  securityBank = 'securitybank',
  unionBank = 'unionbank',
  pnb = 'pnb',
  chinabank = 'chinabank',
  eastWestbank = 'eastWestbank',
  landbank = 'landbank',
  metrobank = 'metrobank',
  rcbc = 'rcbc',
  robinsonsbank = 'robinsonsbank',
  ingbank = 'ingbank',
  aub = 'aub',
  helloMoney = 'helloMoney',

  binancePay = 'binancepay',

  btc = 'btc',
  eth = 'eth',
  usdt = 'usdt',
  bnb = 'bnb',
  usdc = 'usdc',
  xrp = 'xrp',

  paypal = 'paypal',
  googlePay = 'googlepay',
  cashapp = 'cashapp',
  venmo = 'venmo',
  strike = 'strike',
  zelle = 'zelle',
  revolut = 'revolut',

  paypalGateway = 'paypalGateway',
  stripe = 'stripe',
  paymongo = 'paymongo',
  xendit = 'xendit',
  dragonPay = 'dragonPay'
}
